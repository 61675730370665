/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

$primary: #90c5c1;
$secondary: #3394a4;
$body-bg: #fff;

@import '~bootstrap/scss/bootstrap.scss';
@import './styles/theme.scss';

@import './styles/fonts/futura.scss';
@import './styles/fonts/gilroy.scss';
@import './styles/_card';
@import './styles/_layout';
