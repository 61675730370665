.report-page {
  h3 {
    font-family: 'Gilroy';
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0;

    span {
      display: block;
      margin-top: 8px;
      font-weight: 500;
    }

    &.report-page__candidate span {
      font-weight: 700;
      width: 100%;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.005em;
      color: var(--color-green-5);
    }
  }

  &__assessment-question-rate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}
