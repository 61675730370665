@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewMedium-Obl.eot');
  src: local('Futura New Medium Obl'), local('../../assets/fonts/futura/FuturaNewMedium-Obl'),
    url('../../assets/fonts/futura/FuturaNewMedium-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewMedium-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewMedium-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewMedium-Obl.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New Demi';
  src: url('../../assets/fonts/futura/FuturaNewDemi-Obl.eot');
  src: local('Futura New Demi Obl'), local('../../assets/fonts/futura/FuturaNewDemi-Obl'),
    url('../../assets/fonts/futura/FuturaNewDemi-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewDemi-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewDemi-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewDemi-Obl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewMedium-Reg.eot');
  src: local('Futura New Medium Reg'), local('../../assets/fonts/futura/FuturaNewMedium-Reg'),
    url('../../assets/fonts/futura/FuturaNewMedium-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewMedium-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewMedium-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewMedium-Reg.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewHeavy-Reg.eot');
  src: local('Futura New Heavy Reg'), local('../../assets/fonts/futura/FuturaNewHeavy-Reg'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Reg.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewLight-Obl.eot');
  src: local('Futura New Light Obl'), local('../../assets/fonts/futura/FuturaNewLight-Obl'),
    url('../../assets/fonts/futura/FuturaNewLight-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewLight-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewLight-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewLight-Obl.ttf') format('truetype');
  font-weight: 450;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New Book';
  src: url('../../assets/fonts/futura/FuturaNewBook-Obl.eot');
  src: local('Fu../../assets/fonts/futura/tura New Book Obl'), local('../../assets/fonts/futura/FuturaNewBook-Obl'),
    url('../../assets/fonts/futura/FuturaNewBook-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewBook-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewBook-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewBook-Obl.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewExtraBold-Obl.eot');
  src: local('Futura New ExtraBold Obl'), local('../../assets/fonts/futura/FuturaNewExtraBold-Obl'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Obl.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New Book';
  src: url('../../assets/fonts/futura/FuturaNewBook-Reg.eot');
  src: local('Futura New Book Reg'), local('../../assets/fonts/futura/FuturaNewBook-Reg'),
    url('../../assets/fonts/futura/FuturaNewBook-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewBook-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewBook-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewBook-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewBold-Obl.eot');
  src: local('Futura New Bold Obl'), local('../../assets/fonts/futura/FuturaNewBold-Obl'),
    url('../../assets/fonts/futura/FuturaNewBold-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewBold-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewBold-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewBold-Obl.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewExtraBold-Reg.eot');
  src: local('Futura New ExtraBold Reg'), local('../../assets/fonts/futura/FuturaNewExtraBold-Reg'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewExtraBold-Reg.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewLight-Reg.eot');
  src: local('Futura New Light Reg'), local('../../assets/fonts/futura/FuturaNewLight-Reg'),
    url('../../assets/fonts/futura/FuturaNewLight-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewLight-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewLight-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewLight-Reg.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New Demi';
  src: url('../../assets/fonts/futura/FuturaNewDemi-Reg.eot');
  src: local('Futura New Demi Reg'), local('../../assets/fonts/futura/FuturaNewDemi-Reg'),
    url('../../assets/fonts/futura/FuturaNewDemi-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewDemi-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewDemi-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewDemi-Reg.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewBold-Reg.eot');
  src: local('Futura New Bold Reg'), local('../../assets/fonts/futura/FuturaNewBold-Reg'),
    url('../../assets/fonts/futura/FuturaNewBold-Reg.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewBold-Reg.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewBold-Reg.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewBold-Reg.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('../../assets/fonts/futura/FuturaNewHeavy-Obl.eot');
  src: local('Futura New Heavy Obl'), local('../../assets/fonts/futura/FuturaNewHeavy-Obl'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Obl.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Obl.woff2') format('woff2'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Obl.woff') format('woff'),
    url('../../assets/fonts/futura/FuturaNewHeavy-Obl.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
