.authContainer {
  display: flex;
  flex-direction: column;
  background: #fff;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: inherit;
  width: 40vw;
  @media only screen and (max-width: 960px) {
    width: 100vw;
  }

  .title {
    font-family: 'Futura New';
    letter-spacing: 0.03em;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: var(--color-green-5);
    margin-bottom: 1em;
  }
}
