.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.icon {
  max-width: 40px;
  margin-left: 11px;
}

.text {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-black);
}
