.form {
  &__label {
    padding: 0;
    margin: 0;
    display: block;
    cursor: pointer;

    &__input {
      display: none;

      &:checked + .form__label__box {
        background-color: #edf6f8;
        box-shadow: inset 0 0 0 2px #0c7989;

        .form__label__name {
          color: #0c7989;
        }
      }
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-height: 40px;
      box-shadow: inset 0 0 0 1px #d5e5e8;
      border-radius: 3px;
      transition: box-shadow 0.2s ease;
      padding: 0 20px;

      &.is-error {
        box-shadow: 1px 1px 12px 0 rgb(220 53 69 / 20%);

        .form__label__name {
          color: #dc3545;
        }
      }
    }

    &__name {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      color: #98b1b5;
      transition: all 0.2s ease;
    }
  }
}

.errors {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #dc3545;
  margin-top: -10px;
  margin-bottom: 15px;

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
}
