.r-select {
  .r-select {
    &__control {
      width: 100%;
      height: 55px;
      padding-left: 12px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 500;
      border-radius: 3px;
      color: var(--color-green-light-1);
      border: 1px solid #d0e2e7;
      background-color: #deeaed;
      transition: all 0.3s;
    }
    &__multi-value {
      justify-content: center;
      padding: 4px;
      background-color: var(--color-white);
      box-shadow: inset 0 0 0 1px var(--color-green-light-1);
      border-radius: 3px;
      transition: box-shadow 0.2s ease;

      &__label {
        text-align: center;
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
        color: var(--color-green-4);
        transition: all 0.2s ease;
        margin-right: 8px;
      }
    }

    &__menu-list {
      border: 1px solid #d0e2e7;
      background-color: var(--color-white);
    }

    &__option {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
      color: var(--color-dark);

      &--is-focused {
        background-color: #deeaed;
      }
    }
  }
}
