.container {
  min-height: 100%;
  height: auto;
  background: linear-gradient(180deg, rgba(222, 238, 239, 0) 0%, #deeeef 100%);
  position: relative;
  overscroll-behavior: none;
}

.wrapper {
  --position-from-top: 18vh;
  position: absolute;
  left: 0;
  top: var(--position-from-top);
  width: 100%;
  height: calc(100% - var(--position-from-top));
  background-image: url('../../assets/svg/GWCurve-1.svg');
  background-repeat: no-repeat;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;
  overscroll-behavior: none;
}
