.card {
  background-color: var(--color-white);
  box-shadow: 0px 0px 15px rgba(12, 121, 137, 0.21);
  border-radius: 15px;
  transition: 0.15s all ease-in;
  padding: 30px !important;
  width: 100%;

  &.active,
  &:focus {
    box-shadow: 0px 0px 15px #0c7989;
  }
}
