@import './mixins.scss';

:root {
  /* colors */
  --color-black: hsla(0, 0%, 0%, 1);
  --color-dark: hsl(191deg, 30%, 14%, 1);
  --color-dark-2: #18292d;

  --color-green-1: hsl(190deg, 77%, 22%, 1);
  --color-green-2: hsl(189deg, 77%, 31%, 1);
  --color-green-3: hsl(185deg, 32%, 46%, 1);
  --color-green-4: hsl(175deg, 31%, 67%, 1);
  --color-green-5: #007987;
  --color-green-6: hsla(191, 74%, 15%, 1);
  --color-green-7: hsla(186, 100%, 26%, 1);
  --color-green-8: hsla(186, 100%, 26%, 0.1);
  --color-green-9: hsla(186, 100%, 26%, 0.2);
  --color-green-10: hsla(188, 84%, 29%, 0.1);
  --color-green-11: hsla(188, 84%, 29%, 1);
  --color-green-12: hsl(193, 82%, 15%);
  --color-green-13: hsl(191, 16%, 55%);

  --color-orange: hsl(8deg, 63%, 57%, 1);
  --color-white: hsl(0deg, 0%, 100%, 1);
  --bg-light-blue: hsl(185deg, 55%, 96%, 1);

  --color-red: hsla(8, 60%, 56%, 1);

  --color-grey: hsla(0, 0%, 0%, 0.25);
  --color-green-light-1: hsl(165, 22%, 70%);

  /* gradients */
  --gradient-green: linear-gradient(90deg, var(--color-green-1) 0%, var(--color-green-2) 100%);

  /* fonts */
  --font-gilroy: 'Gilroy';
  --font-futura-new: 'Futura New';

  .btn {
    transition: 0.2s all ease-out;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 700;
    color: var(--color-white);
    background-color: var(--color-green-7);
    margin: 3px;

    &:not(.btn-sm) {
      border-radius: 30px;
      font-size: 18px;
      line-height: 137.8%;
      padding: 17px 70px;
    }

    &-secondary {
      color: #ffffff;
    }
  }
}

h1 {
  color: var(--color-green-1);
  font-family: Futura New;
  font-size: 52px;
  font-weight: 600;
  line-height: 61px;
  margin-bottom: 60px;
  margin-top: 0;
}

h2 {
  color: var(--color-green-1);
  font-family: Futura New;
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
  margin-bottom: 60px;
  margin-top: 0;
}

h3 {
  color: var(--color-green-1);
  font-family: Futura New;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 20px;
  margin-top: 0;
}

p {
  color: var(--color-green-7);
  font-family: Futura New;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

body {
  margin: 0;
  font-family: Gilroy, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  height: 100vh;
  background: white;
  font-family: sans-serif;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overscroll-behavior: none;
}

.screen {
  padding-top: 168px;
  padding-bottom: 20px;
}

#root {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

button {
  background-color: unset;
  border: none;
  appearance: none;
  cursor: pointer;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

.container {
  max-width: 1280px;
}

.button-container {
  max-width: 250px;
}

.checkbox-container {
  margin-bottom: 20px;
}

.form-component {
  position: relative;
  padding-bottom: 20px;
}

.label {
  display: inline-block;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  color: var(--color-green-13);
  padding: 0;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;

  p {
    margin-bottom: 0;
    margin-top: 6px;
    font-size: 15px;
    line-height: 18px;
    color: var(--color-grey);
  }
}

.errors {
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 170px;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-red);

  @media screen and (max-width: 1023px) {
    font-size: 12px;
  }
}

.input {
  display: block;
  width: 100%;
  height: 55px;
  padding: 16px 24px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;
  border-radius: 3px;
  color: var(--color-green-13);
  border: 1px solid #d0e2e7;
  background-color: #deeaed;
  transition: all 0.3s;
  &:focus {
    outline: none;
    border-color: var(--color-black);

    &::placeholder {
      color: var(--color-black);
    }
  }

  &.input__error {
    border-color: var(--color-red);

    &:focus {
      border-color: var(--color-green-13);

      &::placeholder {
        color: var(--color-green-13);
      }
    }
  }

  &textarea {
    height: 140px;
    resize: none;

    @media screen and (min-width: 1024px) {
      height: 100px;
    }
  }

  &::placeholder {
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: var(--color-green-13);
  }
}

th {
  padding: 0 8px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-green-7);
  font-family: Futura New;
  font-size: 13px;
  line-height: 15px;
  font-weight: 400;
}

td {
  padding: 0 8px;
  text-align: center;
  color: var(--color-dark-2);
  font-family: Futura New;
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
}

.row {
  &.dash {
    position: relative;
    &::after {
      content: '';
      width: 100%;
      margin: 24px 0 14px;
      border: 0.5px solid #007987;
    }
  }
}

.no-print {
  @media print {
    visibility: hidden;
  }
}
