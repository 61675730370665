@import './_mixins';

.grid-layout {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 45px 41px;
  padding: 45px 0;

  @include lg {
    justify-content: flex-start;
  }
}
