.question-box .question-box {
  &__range {
    position: absolute;
    right: 12px;
    top: 0;
    width: 134px;

    &::before {
      content: attr(value);
      position: absolute;
      top: -1px;
      left: -25px;
      color: var(--color-green-1);
    }
  }
}
