@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-ExtraBold.ttf');
  src: local('Gilroy Extrabold'), local('Gilroy-ExtraBold'),
    /* url("../../assets/fonts/gilroy/Gilroy-Extrabold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Extrabold.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-UltraLightItalic.ttf');
  src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-UltraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-UltraLightItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-UltraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-BoldItalic.ttf');
  src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
    url('../../assets/fonts/gilroy/Gilroy-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilroy/Gilroy-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/gilroy/Gilroy-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-SemiBold.ttf');
  src: local('Gilroy Semibold'), local('Gilroy-SemiBold'),
    /* url("../../assets/fonts/gilroy/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Semibold.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-BlackItalic.ttf');
  src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
    url('../../assets/fonts/gilroy/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilroy/Gilroy-BlackItalic.woff') format('woff'),
    url('../../assets/fonts/gilroy/Gilroy-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-ThinItalic.ttf');
  src: local('Gilroy Thin Italic'), local('Gilroy-ThinItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-ThinItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-ExtraBoldItalic.ttf');
  src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-ExtraboldItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-ExtraboldItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Black.ttf');
  src: local('Gilroy Black'), local('Gilroy-Black'),
    url('../../assets/fonts/gilroy/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilroy/Gilroy-Black.woff') format('woff'),
    url('../../assets/fonts/gilroy/Gilroy-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-HeavyItalic.ttf');
  src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-HeavyItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-HeavyItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Regular.ttf');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    /* url("../../assets/fonts/gilroy/Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Regular.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-SemiBoldItalic.ttf');
  src: local('Gilroy Semibold Italic'), local('Gilroy-SemiBoldItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-SemiboldItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-SemiboldItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Medium.ttf');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    /* url("../../assets/fonts/gilroy/Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Medium.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Bold.ttf');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
    url('../../assets/fonts/gilroy/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/gilroy/Gilroy-Bold.woff') format('woff'),
    url('../../assets/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-RegularItalic.ttf');
  src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-RegularItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-RegularItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Light.ttf');
  src: local('Gilroy Light'), local('Gilroy-Light'),
    /* url("../../assets/fonts/gilroy/Gilroy-Light.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Light.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-LightItalic.ttf');
  src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-LightItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Heavy.ttf');
  src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
    /* url("../../assets/fonts/gilroy/Gilroy-Heavy.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Heavy.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-MediumItalic.ttf');
  src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
    /* url("../../assets/fonts/gilroy/Gilroy-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-MediumItalic.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Gilroy;
  src: url('../../assets/fonts/gilroy/Gilroy-Thin.ttf');
  src: local('Gilroy Thin'), local('Gilroy-Thin'),
    /* url("../../assets/fonts/gilroy/Gilroy-Thin.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/gilroy/Gilroy-Thin.woff") format("woff"), */
      url('../../assets/fonts/gilroy/Gilroy-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
